/*
 * @Date: 2021-08-12 11:24:10
 * @LastEditors: YuWenYun
 * @LastEditTime: 2021-08-12 22:25:21
 * @Description: 
 * @version: 
 * @Author: YuWenYun
 */
// components/index.js
import Vue from 'vue';

// 自动加载 global 目录下的 .vue 结尾的文件
const componentsContext = require.context('./', true, /\.vue$/);

componentsContext.keys().forEach(component => {
  const componentConfig = componentsContext(component);
  /**
  * 兼容 import export 和 require module.export 两种规范
  */
  const ctrl = componentConfig.default || componentConfig;//创建组件时必须填写name，不然会报错
  Vue.component(ctrl.name, ctrl);
});
