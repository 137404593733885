import Vue from 'vue';
import Router from 'vue-router';

/**
 * author: qi.chen
 * 描述: 自动加载 router 目录下的 indexs.js 结尾的文件
 */
Vue.use(Router);
let routers = [];
const routerContext = require.context('./', true, /indexs\.js$/);
routerContext.keys().forEach(route => {
  if (route.startsWith('./index')) {
    return;
  }
  const routerModule = routerContext(route);
  routers = [...routers, ...(routerModule.default || routerModule)];
});

export default new Router({
  routes: routers
});
