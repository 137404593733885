<!--
 * @Date: 2021-08-12 21:53:39
 * @LastEditors: YuWenYun
 * @LastEditTime: 2021-08-20 15:32:23
 * @Description: loading
 * @version: 
 * @Author: YuWenYun
-->
<template>
  <div class="loading" style="z-index:9999">
    <div class="item-inner" v-show="loadingAnimate.name === 'loading03'">
      <div class="item-loader-container">
        <div class="la-square-jelly-box la-2x">
          <div
            :style="{
              background: loadingAnimate.bgColor
            }"
          ></div>
          <div></div>
        </div>
        <h1
        :style="{
          color: loadingAnimate.bgColor
        }"
      >
        加载中...
      </h1>
      </div>
    </div>
    <div class="loader" v-show="loadingAnimate.name === 'loading01'">
      <div class="loader-inner line-spin-fade-loader">
        <div :style="{ backgroundColor: loadingAnimate.bgColor }"></div>
        <div :style="{ backgroundColor: loadingAnimate.bgColor }"></div>
        <div :style="{ backgroundColor: loadingAnimate.bgColor }"></div>
        <div :style="{ backgroundColor: loadingAnimate.bgColor }"></div>
        <div :style="{ backgroundColor: loadingAnimate.bgColor }"></div>
        <div :style="{ backgroundColor: loadingAnimate.bgColor }"></div>
        <div :style="{ backgroundColor: loadingAnimate.bgColor }"></div>
        <div :style="{ backgroundColor: loadingAnimate.bgColor }"></div>
      </div>
      <h1
        :style="{
          color: loadingAnimate.bgColor
        }"
      >
        加载中...
      </h1>
    </div>
    <div id="container" v-show="loadingAnimate.name === 'loading02'">
      <div
        class="stick"
        :style="{
          background: loadingAnimate.bgColor
        }"
      ></div>
      <div
        class="stick"
        :style="{
          background: loadingAnimate.bgColor
        }"
      ></div>
      <div
        class="stick"
        :style="{
          background: loadingAnimate.bgColor
        }"
      ></div>
      <div
        class="stick"
        :style="{
          background: loadingAnimate.bgColor
        }"
      ></div>
      <div
        class="stick"
        :style="{
          background: loadingAnimate.bgColor
        }"
      ></div>
      <div
        class="stick"
        :style="{
          background: loadingAnimate.bgColor
        }"
      ></div>
      <h1
        :style="{
          color: loadingAnimate.bgColor
        }"
      >
        加载中...
      </h1>
    </div>
    <div class="item" v-show="loadingAnimate.name === 'loading04'">
      <div class="item-inner">
        <div class="item-loader-container">
          <div class="la-line-scale la-2x">
            <div
              :style="{
                background: loadingAnimate.bgColor
              }"
            ></div>
            <div
              :style="{
                background: loadingAnimate.bgColor
              }"
            ></div>
            <div
              :style="{
                background: loadingAnimate.bgColor
              }"
            ></div>
            <div
              :style="{
                background: loadingAnimate.bgColor
              }"
            ></div>
            <div
              :style="{
                background: loadingAnimate.bgColor
              }"
            ></div>
          </div>
          <h1
            :style="{
              color: loadingAnimate.bgColor
            }"
          >
            加载中...
          </h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'loading',
  props: {
    loadingAnimate: {
      type: Object,
      default: () => {
        return {
          name: '',
          bgColor: ''
        };
      }
    }
  }
};
</script>

<style lang="less"></style>
